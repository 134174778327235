<template>
  <div>
    <b-modal
      id="modal-1"
      ref="group-modal"
      title="Basic Modal"
      hide-footer
    >
      <span>Имя шаблона</span>
      <b-form-input
        v-model="template.name"
        placeholder="Имя шаблона"
        class="inline-block"
      />

      <div
        v-for="(group, i) in template.groups"
        :key="'template.groups_' + i"
      >
        <div>Группа {{ i + 1 }}
          <b-button
            title="Удалить"
            variant="flat-danger"
            class="btn-icon inline-block"
            size="sm"
            @click="template.groups.splice(i, 1)"
          >
            <feather-icon icon="MinusIcon" />
          </b-button>
        </div>
        <b-form-input
          v-model="group.name"
          placeholder="Название"
          class="inline-block"
        />
        <div class="inline-block">
          <b-form-checkbox
            v-model="group.sex"
            class="mt-1 mr-1"
            value="male"
          >М
          </b-form-checkbox>
        </div>
        <div class="inline-block">
          <b-form-checkbox
            v-model="group.sex"
            value="female"
          >Ж
          </b-form-checkbox>
        </div>
        <div style="margin-top: 40px">
          Возраст
        </div>
        <div class="age">
          <b-form-input
            v-model="group.ageFrom"
            style="margin-top: 10px!important"
            placeholder="От"
            class="inline-block"
          />
          <b-form-input
            v-model="group.ageTo"
            style="margin-top: 10px!important"
            placeholder="До"
            class="inline-block"
          />
        </div>
      </div>
      <b-button
        class="mt-3"
        variant="primary"
        size="small"
        @click="template.groups.push({name: '', sex: '', ageFrom:'', ageTo: ''})"
      >Добавить группу
      </b-button>
      <div>
        <b-button
          style="margin-top: 10px!important;"
          class="mt-3"
          variant="primary"
          size="small"
          @click="updateTemplate"
        >Сохранить
        </b-button>
      </div>

    </b-modal>

    <b-button
      v-b-modal.modal-1
      variant="primary"
      class="mr-1"
      @click="template = {
        name: '',
        groups: []
      }"
    >
      Добавить шаблон
    </b-button>
    <div class="wrapper">
      <div
        v-for="(group, i) in groups"
        :key="'all-groups_' + i"
        style="margin-top: 20px; margin-bottom: 0!important;"
        class="card"
      ><div class="card-header">
        <div><h4 class="card-title">
          {{ group.name }}
        </h4></div>
      </div><div class="card-body">

        <b-button
          v-b-modal.modal-1
          variant="primary"
          class="mr-1"
          @click="getTemplate(group.id)"
        >
          Редактировать шаблон
        </b-button>
        <b-button
          variant="primary"
          class="mr-1"
          @click="deleteTemplate(group.id)"
        >
          Удалить шаблон
        </b-button>
      </div>

      </div></div>

  </div>
</template>

<script>
import crud_module from '@/libs/crud_module'
import {
  VBPopover, BFormCheckbox, BButton, BFormInput,
} from 'bootstrap-vue'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const group_module = crud_module({
  entity_name: 'group-template',
})

export default {
  name: 'GroupPage',
  components: {
    BFormInput,
    BFormCheckbox,
    BButton,
  },

  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return {
      template: {
        name: '',
        groups: [],
      },
      groups: [],
    }
  },

  created() {
    this.getTemplates()
  },
  methods: {
    async deleteTemplate(id) {
      await group_module.deleteItem(id)

      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Уведомление',
          icon: 'BellIcon',
          text: 'Шаблон удален',
          variant: 'success',
        },
      })
      this.getTemplates()
    },
    updateTemplate() {
      if (this.template.id) {
        this.saveTemplate()
      } else {
        this.createTemplate()
      }
    },
    async saveTemplate() {
      const newArr = this.template.groups.map(group => {
        if (Array.isArray(group.sex)) {
          const formatedGroup = {
            ...group,
            sex: group.sex[0]
          }

          return formatedGroup
        }

        return group
      })
    
      const result = await group_module.updateItem(this.template.id, {
        name: this.template.name,
        code: JSON.stringify(newArr),
      }).then(r => r.data)
      if (result.success) {
        this.$emit('save')
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Уведомление',
            icon: 'BellIcon',
            text: 'Шаблон сохранен',
            variant: 'success',
          },
        })
        this.$refs['group-modal'].hide()
        this.template = {
          name: '',
          groups: [],
        }
        this.getTemplates()
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Уведомление',
            icon: 'BellIcon',
            text: 'Ошибка сохранения',
            variant: 'danger',
          },
        })
      }
    },
    async createTemplate() {
      const result = await group_module.createItem({
        name: this.template.name,
        code: JSON.stringify(this.template.groups),
      }).then(r => r.data)
      if (result.success) {
        this.$emit('save')
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Уведомление',
            icon: 'BellIcon',
            text: 'Шаблон сохранен',
            variant: 'success',
          },
        })
        this.$refs['group-modal'].hide()
        this.template = {
          name: '',
          groups: [],
        }
        this.getTemplates()
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Уведомление',
            icon: 'BellIcon',
            text: 'Ошибка сохранения',
            variant: 'danger',
          },
        })
      }
    },
    async getTemplate(id) {
      const template = await group_module.fetchItem(id).then(r => r.data)
      this.template.id = template.id
      this.template.name = template.name
      this.template.groups = JSON.parse(template.code)
    },
    async getTemplates() {
      this.groups = await group_module.fetchItems().then(r => r.data.rows)
    },

  },
}
</script>

<style scoped lang="scss">
.wrapper{
  display: flex;
 margin: 0 -15px;
  flex-wrap: wrap;
  .card{
    width: calc(33.33% - 30px);
    margin: 15px;
    @media (max-width: 1500px){
    width: calc(50% - 30px);
    }
    @media (max-width: 767px){
    width: 100%;
    }
  }
  .card-body{
    display: flex;
  }
}

</style>
